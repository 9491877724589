
import GmapsAddress from "../gmaps-address";
import staffing from "../../mixins/staffing";
import modals from "../../mixins/modals";
import UserCoverageModal from "../users/user-coverage-modal";
import FavStar from "../fav-star";
import RedFlag from "../red-flag";
import {getGoogleFinanceUrl, getYahooFinanceUrl} from "../../mixins/stock-name-conversion";
import {OPTION_COMPANY, OPTION_FUND} from "../../mixins/company-classification";
import {SEVERITY_HIGH, SEVERITY_MEDIUM} from "../../mixins/red-flag";
import Restricted from "../restricted.vue";
import InvestorTargetsModal from "../investor-targets/investor-targets-modal.vue";
import EditDetailsModal from "./edit-details-modal";
import KnownPeopleModal from "./known-people-modal"
import DeleteConfirmModal from "./delete-confirm-modal";
import InvolvementReportModal from "./involvement-report-modal";
import PitRequestModal from "./pit-request-modal";
import NdaModal from "./nda-modal.vue";
import CompanyDataPointQuickEdit from "./company-data-point-quick-edit.vue";
import StockAlertModal from "./stock-alert-modal.vue";
import optionButtonTooltip from "~/mixins/option-button-tooltip";
import RequestStaffingModal from "~/components/company/request-staffing-modal"

export default {
    name: "DetailsCard",
    components: {
        StockAlertModal,
        InvestorTargetsModal,
        CompanyDataPointQuickEdit,
        Restricted,
        NdaModal,
        RedFlag,
        FavStar,
        DeleteConfirmModal,
        UserCoverageModal,
        GmapsAddress,
        EditDetailsModal,
        KnownPeopleModal,
        InvolvementReportModal,
        RequestStaffingModal,
        PitRequestModal,
    },
    mixins: [optionButtonTooltip, staffing, modals],
    props: {
        company: {
            type: Object,
            required: true
        },
        id: {
            type: Number,
            required: false,
            default: null,
        },
    },
    data() {
        return {
            OPTION_COMPANY,
            OPTION_FUND,
            SEVERITY_HIGH,
            SEVERITY_MEDIUM,
            modal: null,
            stockInfo: {},
            uuid: this.generateUUID(),
            isFlaggedByCurrentUser: false,
            combinedRatings: [],
            knownPeople: [],
            fromContactId: [],
            continuingKnownPeople: [],
            continuingFromContactId: [],
            coverageUserId: null,
            showRedFlagModalTrigger: null,
            showRestrictedModalTrigger: null,
            pitRequestModalKey: "pitRequestModal",
            ndaModalKey: "ndaModal",
            investorTargetsModalKey: "investorTargetsModal",
            allKnownPeopleAreInactive: true,
        }
    },
    computed: {
        yahooFinanceUrl() {
            return getYahooFinanceUrl(this.company.ticker);
        },
        googleFinanceUrl() {
            if (this.stockInfo && this.stockInfo.profile) {
                return getGoogleFinanceUrl(this.company.ticker, this.stockInfo.profile.exchangeShortName);
            } else {
                return false;
            }
        },

        formattedMarketCap() {
            if (this.stockInfo && this.stockInfo.profile && this.stockInfo.profile.mktCap) {
                return this.nFormatCurrency(this.stockInfo.profile.currency, this.stockInfo.profile.mktCap);
            } else {
                return null;
            }
        },
        isForCompany() {
            return this.company.id !== null;

        },
        cashAndShortTermInvestments() {
            if (this.stockInfo && this.stockInfo.cashAndShortTermInvestments) {
                return this.stockInfo.cashAndShortTermInvestments;
            } else {
                return null;
            }
        },
        assetsUnderManagement() {
            if (this.stockInfo && this.stockInfo.assetsUnderManagement) {
                return this.stockInfo.assetsUnderManagement;
            } else {
                return null;
            }
        },
        cashBurn() {
            if (this.stockInfo && this.stockInfo.cashBurn) {
                return this.stockInfo.cashBurn;
            } else {
                return null;
            }
        },
        ipoDate() {
            if (this.stockInfo && this.stockInfo.profile && this.stockInfo.profile.ipoDate) {
                return this.formatDate(this.stockInfo.profile.ipoDate);
            } else {
                return null;
            }
        },
        researchReport() {
            if (this.stockInfo && this.stockInfo.researchReport) {
                return {
                    pdfUrl: this.stockInfo.researchReport.pdfUrl,
                    date: this.formatDate(this.stockInfo.researchReport.date),
                };
            } else {
                return null;
            }
        },
        dncReadable() {
            if (this.company && this.company.dnc_comment === "DNC_PER_MV") {
                return "DNC per MV";
            } else {
                return this.company.dnc_comment;
            }
        },
        otherKnowingPeopleNames() {
            return this.formatArray(this.knownPeople.map(person => person.name));
        }
    },
    mounted() {
        this.loadStockInfo();
        this.prepareAnalystRatings();
        this.loadKnownPeople();
    },
    methods: {
        openRedFlagModal() {
            this.showRedFlagModalTrigger = this.generateUUID();
        },
        openRestrictedModal() {
            this.showRestrictedModalTrigger = this.generateUUID();
        },
        redFlagUpdated() {
            this.$emit('company-updated');
        },
        restrictedUpdated() {
            this.$emit('company-updated');
        },
        showEditForm() {
            this.modal = this.openModal(this.$refs.editCompanyModal);
        },
        showKnownPeople() {
            this.modal = this.openModal(this.$refs.knownPeopleModal);
        },
        showInvolvementReportModal() {
            this.modal = this.openModal(this.$refs.involvementReportModal);
        },
        showPitRequestModal() {
            this.pitRequestModalKey = this.generateUUID();
            this.$nextTick(() => {
                this.modal = this.openModal(this.$refs.pitRequestModal);
            });
        },
        companyUpdated() {
            this.closeModal(this.modal);
            this.$emit('company-updated');

        },
        loadStockInfo() {
            this.$axios.get("/api/companies/details_stock_info/" + this.company.id)
                .then(response => {
                    this.stockInfo = response.data;
                });
        },
        loadKnownPeople() {
            this.$axios.get(`/api/companies/known_people/${this.company.id}?groups[]=knownPeople:read`)
                .then((response) => {
                    console.log(response.data)
                    this.knownPeople = response.data.relationships;
                    this.fromContactId = response.data.fromContactId;
                    this.continuingKnownPeople = response.data.continuingRelationships;
                    this.continuingFromContactId = response.data.continuingFromContactId;
                    for (let i = 0; i < this.knownPeople.length; i++) {
                        if(this.knownPeople[i].toContact.isActive && this.knownPeople[i].fromContact.isActive) {
                            this.allKnownPeopleAreInactive = false;
                            break;
                        }
                    }
                });
        },
        prepareAnalystRatings() {
            const ratings = [];

            // We cannot go further if there are no research reports available
            if (!this.company.researchReportsPublishedProcessed) {
                this.combinedRatings = ratings;
                return;
            }

            for (let j = 0; j < this.company.analystsWithRatings.length; j++) {
                const analystWithRating = this.company.analystsWithRatings[j];

                const name = analystWithRating.user.contact.lastName;
                const userId = analystWithRating.user.id;

                const reportsForUser = this.company.researchReportsPublishedProcessed.filter((r) => {
                    const authorUserIds = r.authorUsers.map(r => r.id);
                    return authorUserIds.includes(userId);
                });

                const firstReportForUSer = reportsForUser[0] ?? null;

                const rated = firstReportForUSer ? firstReportForUSer.rating : "";
                const priceTarget = firstReportForUSer ? firstReportForUSer.priceTarget : null;
                const currency = firstReportForUSer ? firstReportForUSer.currency : "";

                let ratingFound = false;

                for (let i = 0; i < ratings.length; i++) {
                    if (
                        ratings[i].rating === rated &&
                        ratings[i].priceTarget === priceTarget &&
                        ratings[i].currency === currency
                    ) {
                        ratings[i].users.push(
                            {
                                name,
                                id: userId
                            }
                        );
                        ratingFound = true;
                        break;
                    }
                }

                if (!ratingFound) {
                    // Prepare Data
                    const rating = {
                        users: [{
                            name,
                            id: userId
                        }],
                        'rating': rated,
                        priceTarget,
                        currency
                    }

                    ratings.push(rating);
                }
            }

            this.combinedRatings = ratings;
        },
        showCoverage(userId) {
            this.coverageUserId = userId;
            this.$nextTick(() => {
                this.modal = this.openModal(this.$refs.userCoverageModal);
            });
        },
        deleteCompany() {
            this.modal = this.openModal(this.$refs.deleteCompanyModal);
        },
        doDeleteCompany(id) {
            this.$axios.delete("/api/companies/" + id).then(() => {
                const toastId = this.generateUUID();
                this.addToast({
                    type: "success",
                    title: "Deleted",
                    message: "Company deleted",
                    id: toastId,
                });
                this.$nextTick(() => {
                    this.toggleToast(toastId);
                    this.$router.push('/companies');
                })
            });
        },
        doReplaceCompany(data) {
            this.loading = true;

            this.$axios.post("/api/companies/merge_delete", {
                toDeleteId: data.companyId,
                replaceById: data.replaceId,
            }).then(() => {
                const toastId = this.generateUUID();
                this.addToast({
                    type: "success",
                    title: "Replace",
                    message: "Company replaced",
                    id: toastId,
                });
                this.$nextTick(() => {
                    this.toggleToast(toastId);
                    this.$router.push('/companies/' + data.replaceId);
                })
            });
        },
        firstUpperCase(string) {
            if (!string) return "";
            return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
        },
        onPitRequestSent() {
            this.closeModal(this.modal);
            this.modal = null;
        },
        editNDAInformation() {
            this.modal = this.openModal(this.$refs.ndaModal);
        },
        onNdaModalClosed(updated = false) {
            this.closeModal(this.modal);
            this.modal = null;
            this.ndaModalKey = this.generateUUID();
            if (updated) {
                this.$emit('company-files-updated');
                this.$emit('company-updated');
            }
        },
        async showInvestorTargets() {
            this.investorTargetsModalKey = this.generateUUID();
            await this.$nextTick();
            this.openModal(this.$refs.investorTargetsModal);
        },
        openStockAlertModal() {
            this.openModal(this.$refs.stockAlertModal);
        }
    }
}
