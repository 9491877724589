
import {CLASSIFICATION_OPTIONS} from "../../mixins/company-classification";
import LoadingSpinner from "../loading-spinner";
import SheetBook from "./bulk-operations/sheet-book";
import CompanyGroups from "./bulk-operations/company-groups";
import DownloadSelectColumnsForm from "./download-select-columns-form";
import iriPreparation from '~/mixins/iri-preparation';

export default {
    name: "CompanyBulkOperationForm",
    components: {SheetBook, LoadingSpinner, CompanyGroups, DownloadSelectColumnsForm},
    mixins: [iriPreparation],
    props: {
        filters: {
            type: Object,
            default: () => {
                return {}
            }
        },
        companyGroup: {
            type: Object,
            default: null,
        },
        bulkCompanies: {
            type: Array,
            required: true
        },
        companyLoading: {
            type: Boolean,
            required: true
        },
        event: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            operation: null,
            groupOption: "existing",
            groupOptions: [
                {value: 'existing', label: 'Existing Group'},
                {value: 'new', label: 'New Group'},
            ],
            running: false,
            loading: false,
            submitting: false,
            step: 1,
            companyCharacteristics: [],
            characteristicOptions: [],
            selectedEvent: null,
            isBdTargetManual: null,
            classificationManual: null,
            restrictionNotes: "",
            restrictionAtm: false,
            dateRestrictionAdded: null,
            dateRestrictionRemoved: null,
            CLASSIFICATION_OPTIONS,
            openNewsInNewTab: true,
            companyHash: null,
            operation2: null,
        }
    },
    computed: {
        currentRef() {
            switch (this.operation || this.operation2) {
                case 'group_existing':
                case 'group_new':
                case 'group_remove':
                case 'group_remove_all':
                    return 'companyGroups';
                case 'generate_book':
                    return 'sheetBook';
                case 'add_company_characteristic':
                case 'add_company_to_event':
                case 'change_classification':
                case 'add_restriction':
                case 'show_news':
                case 'create_email_draft':
                case 'export_as_excel':
                    return 'noRef';
                case 'export_company_label':
                    return 'noRef';
                case 'export_company_name_and_label':
                    return 'noRef';
                case 'copy_to_clipboard':
                    return 'noRef';
                default:
                    return null;
            }
        },
        bulkOptions() {
            const options = [
                {value: 'group_existing', label: 'Add to Existing Group'},
                {value: 'group_new', label: 'Create New Group'},
                {value: 'group_remove', label: 'Remove From Group'},
                {value: 'group_remove_all', label: 'Remove From All Groups'},
                {value: 'add_company_characteristic', label: 'Add Company Characteristic'},
                {value: 'add_company_to_event', label: 'Add Company to Event'},
                {value: 'change_classification', label: 'Change Classification'},
                {
                    value: 'show_news',
                    label: 'Show News for Companies',
                    disabled: this.bulkCompanies.length === 0 || this.bulkCompanies.length > 100,
                    hint: "Limit: 100 companies"
                },
                {value: 'create_email_draft', label: 'Create Email Draft'},
                {value: 'export_as_excel', label: 'Export as Excel'},
                {
                    value: 'copy_to_clipboard',
                    label: 'Copy to Clipboard',
                    disabled: this.bulkCompanies.length === 0 || this.bulkCompanies.length > 100,
                    hint: "Limit: 100 companies"
                },
                {value: 'generate_book', label: 'Generate Book with Company Sheets'}
            ];

            if (this.isUserWithPermissionRestrictedList) {
                options.push({value: 'add_restriction', label: 'Add to Restricted List'});
            }


            return options;
        },
        copyToClipboardOptions() {
            return [
                {value: 'export_company_label', label: 'Company Tickers to Clipboard'},
                {value: 'export_company_name_and_label', label: 'Company Names and Tickers to Clipboard'},
            ];
        },

        downloadQuery() {
            return {
                filters: {
                    companyIds: this.bulkCompanies.map(company => company.id)
                }
            }
        }
    },
    watch: {
        operation(newValue, oldValue) {
            if ((typeof newValue === 'string') && (typeof oldValue !== 'string') && this.step === 1) {
                this.step = 2;
            }
        },
        step(newValue) {
            if (newValue === 2 && this.operation === "show_news") {
                this.prepareCompanyHash();
            }
        }
    },
    created() {
        this.loadCharacteristicOptions();
    },
    methods: {
        runAction() {
            this.$nextTick(() => {
                if (this.currentRef) {

                    // Request confirmation for dangerous operations
                    if (['group_remove', 'group_remove_all'].includes(this.operation)) {
                        let message;
                        switch (this.operation) {
                            case "group_remove":
                                if (!this.$refs[this.currentRef].selectedGroup) {
                                    alert("No Group selected");
                                    return;
                                }
                                message = "You are about to remove " + this.bulkCompanies.length + " companies from a company group. Do you want to proceed?";
                                break;
                            case "group_remove_all":
                                message = "You are about to remove " + this.bulkCompanies.length + " companies from all company groups. Do you want to proceed?";
                                break;
                            default:
                                message = "Do you want to proceed?";
                        }
                        if (!confirm(message)) {
                            return;
                        }
                    }

                    if (this.operation === 'add_company_characteristic') {
                        this.setCompanyCharacteristics()
                    } else if (this.operation === 'add_company_to_event') {
                        this.addCompanyToEvent()
                    } else if (this.operation === 'change_classification') {
                        this.setCompanyClassification();
                    } else if (this.operation === 'add_restriction') {
                        this.addRestrictions();
                    } else if (this.operation === 'show_news') {
                        this.goToCompanyNews();
                        this.$emit("submitted");
                    } else if (this.operation === 'export_as_excel') {
                        this.$refs.columnsForm.download();
                        this.$emit("submitted");
                    } else if (this.operation === 'copy_to_clipboard') {
                        if (this.operation2 === 'export_company_label') {
                            this.exportCompanyLabel(1);
                        } else if (this.operation2 === 'export_company_name_and_label') {
                            this.exportCompanyLabel(2);
                        }
                    } else if (this.operation === 'create_email_draft') {
                        this.createEmailDraft();
                    } else {
                        this.$nextTick(() => {
                            this.submitting = true;
                            this.$refs[this.currentRef].run()
                                .then((result) => {
                                    this.submitting = false;
                                    if (!result.stayOnPage) {
                                        this.$emit("submitted");
                                    }
                                })
                                .catch((error) => {
                                    const toastId = this.generateUUID();
                                    this.addToast({
                                        type: "warning",
                                        title: "Warning",
                                        message: error.message,
                                        id: toastId,
                                    });
                                    this.$nextTick(() => {
                                        this.toggleToast(toastId);
                                    })
                                }).finally(() => {
                                    this.submitting = false;
                                });
                        })
                    }
                }
            });
        },

        /**
         * @param {int} option - The option parameter
         */
        exportCompanyLabel(option) {
            console.log("Exporting company label", option);

            if (option === 1) {
                let tickers = this.bulkCompanies
                    .map(company => company.ticker);

                // Filter empty elements
                tickers = tickers.filter(ticker => ticker !== null && String(ticker).trim() !== '' && ticker !== undefined);

                const combinedString = tickers.join("; ");
                navigator.clipboard.writeText(combinedString);
                // Notify the user
                const toastId = this.generateUUID();
                this.addToast({
                    type: "success",
                    title: "Success",
                    message: "Copying Company Ticker to Clipboard was Successful!",
                    id: toastId,
                });
                this.$nextTick(() => {
                    this.toggleToast(toastId);
                });

            } else if (option === 2) {

                const names = [];

                for (let i = 0; i < this.bulkCompanies.length; i++) {
                    let companyName = "";

                    companyName += String(this.bulkCompanies[i].name).trim();

                    if (this.bulkCompanies[i].isPrivate) {
                        companyName += ' (PRIVATE)';
                    } else if (this.bulkCompanies[i].ticker === null || this.bulkCompanies[i].ticker === '' || this.bulkCompanies[i].ticker === undefined) {
                        companyName += ' (N/A)';
                    } else {
                        companyName += ' (' + this.bulkCompanies[i].ticker + ')';
                    }

                    names.push(companyName.trim());
                }

                const combinedString = names.join("; ");
                navigator.clipboard.writeText(combinedString)
                // Notify the user
                const toastId = this.generateUUID();
                this.addToast({
                    type: "success",
                    title: "Success",
                    message: "Copying Company Name and Ticker to Clipboard was Successful!",
                    id: toastId,
                });
                this.$nextTick(() => {
                    this.toggleToast(toastId);
                });
            }

        },
        loadCharacteristicOptions() {
            this.$axios.get('/api/company_characteristics').then((response) => {
                this.characteristicOptions = response.data['hydra:member'];
            })
        },
        setCompanyCharacteristics() {
            const companyIds = this.bulkCompanies.map((company) => company.id);

            this.companyCharacteristics.forEach((chara) => {
                this.$axios.post(`/api/companies/add_characteristic/${chara.id}`, {
                    company_ids: companyIds,
                }).then((result) => {
                    const toastId = this.generateUUID();
                    this.addToast({
                        type: "success",
                        title: "Characteristic added",
                        message: result.data.message,
                        id: toastId,
                    });
                    this.$nextTick(() => {
                        this.toggleToast(toastId);
                    })
                    this.$emit("submitted");
                });
            });
        },
        addCompanyToEvent() {
            this.submitting = true;

            const companyIds = this.bulkCompanies.map((company) => company.id);

            this.$axios.post(`/api/companies/add_to_event/${this.selectedEvent.id}`, {
                companyIds,
            }).then(response => {
                const toastId = this.generateUUID();
                this.addToast({
                    type: "success",
                    title: "Companies added to Event",
                    message: `${this.pluralize(response.data.numberCompaniesAdded, 'new company was', 'new companies were')} added to ${response.data.eventSubject}` +
                        (response.data.numberCompaniesSkipped > 0 ? (`, ${this.pluralize(response.data.numberCompaniesSkipped, 'company was', 'companies were')} skipped,` +
                            ` because ${this.pluralize(response.data.numberCompaniesSkipped, 'it is', 'they are', null, false)} already participating at the event.`) : '.'),

                    id: toastId,
                });
                this.$nextTick(() => {
                    this.toggleToast(toastId);
                });
                if (response.data.numberAtmRestrictedCompaniesAdded > 0 || response.data.numberNonAtmRestrictedCompaniesAdded > 0) {
                    const restrictedCompaniesMessage = [];
                    if (response.data.numberAtmRestrictedCompaniesAdded > 0) {
                        restrictedCompaniesMessage.push(this.pluralize(response.data.numberAtmRestrictedCompaniesAdded, 'restricted company (ATM)', 'restricted companies (ATM)'));
                    }
                    if (response.data.numberNonAtmRestrictedCompaniesAdded > 0) {
                        restrictedCompaniesMessage.push(this.pluralize(response.data.numberNonAtmRestrictedCompaniesAdded, 'restricted company (not ATM)', 'restricted companies (not ATM)'));
                    }

                    const secondToastId = this.generateUUID();
                    this.addToast({
                        type: "warning",
                        title: "Restricted Companies added to Event",
                        message: restrictedCompaniesMessage.join(' and ') + ' ' +
                            this.pluralize(response.data.numberAtmRestrictedCompaniesAdded + response.data.numberNonAtmRestrictedCompaniesAdded, 'was', 'were', null, false) +
                            ' added to the event.',
                        id: secondToastId,
                    });
                    this.$nextTick(() => {
                        this.toggleToast(secondToastId);
                    });
                }
                this.$emit("submitted");
            }).finally(() => {
                this.submitting = false;
            });
        },
        setCompanyClassification() {
            this.submitting = true;
            const companyIds = this.bulkCompanies.map((company) => company.id);
            const data = {
                companyIds,
                classification: null,
            };

            if (this.classificationManual?.value) {
                data.classification = this.classificationManual?.value;
            }

            this.$axios.post(`/api/companies/update_manual_classification`, data).then((result) => {
                const toastId = this.generateUUID();
                this.addToast({
                    type: "success",
                    title: "Classification updated",
                    message: result.data.message,
                    id: toastId,
                });
                this.$nextTick(() => {
                    this.toggleToast(toastId);
                })
                this.$emit("submitted");
            }).finally(() => {
                this.submitting = false;
            });
        },
        addRestrictions() {
            if (!this.dateRestrictionAdded) {
                const toastId = this.generateUUID();
                this.addToast({
                    type: 'warning',
                    title: 'Warning',
                    message: 'The date added is required',
                    id: toastId,
                });
                this.$nextTick(() => {
                    this.toggleToast(toastId);
                })

                return;
            }

            this.submitting = true;

            const companyIds = this.bulkCompanies.map(company => company.id);

            const data = {
                companyIds,
                notes: this.restrictionNotes,
                atm: this.restrictionAtm,
                dateAdded: this.dateRestrictionAdded,
                dateRemoved: this.dateRestrictionRemoved,
            };

            this.$axios.post('/api/user_marks_company_as_restricteds/add_restrictions', data).then(response => {
                const toastId = this.generateUUID();

                if (response.data.success) {
                    this.addToast({
                        type: "success",
                        title: "Restricted List",
                        message: `Added ${this.pluralize(response.data.numberRestrictionsAdded, 'new restriction')}` +
                            (response.data.numberRestrictionsSkipped > 0
                                ? ` and skipped ${this.pluralize(response.data.numberRestrictionsSkipped, 'company', 'companies')}, because they already had restrictions in the date range.`
                                : '.'),
                        id: toastId,
                    });

                    this.$emit("submitted");

                } else {
                    this.addToast({
                        type: "error",
                        title: "Error",
                        message: response.data.message,
                        id: toastId,
                    });
                }

                this.$nextTick(() => {
                    this.toggleToast(toastId);
                })

            }).finally(() => {
                this.submitting = false;
            });
        },
        createEmailDraft() {
            this.submitting = true;

            const companyIds = this.bulkCompanies.map(company => company.id);

            const data = {
                companyIds,
            };

            this.$axios.post('/api/companies/prepare_email_draft', data).then(response => {
                const toastId = this.generateUUID();
                this.addToast({
                    type: "success",
                    title: "Success",
                    message: 'Email draft has been prepared.',
                    id: toastId,
                });
                this.$nextTick(() => {
                    this.toggleToast(toastId);
                });
                this.$emit("submitted");
                this.$router.push('/emails/' + response.data.id);
            }).finally(() => {
                this.submitting = false;
            });
        },
        prepareCompanyHash() {
            this.$axios.post('/api/companies/prepare_hash', {
                ids: this.bulkCompanies.map((el) => el.id)
            })
                .then((response) => {
                    this.companyHash = response.data.hash;
                });
        },
        goToCompanyNews() {
            if (this.openNewsInNewTab) {
                window.open(`/engine/companies/news/${this.companyHash}`, '_blank').focus();
            } else {
                this.$router.push(`/companies/news/${this.companyHash}`);
            }
        }
    }
}
