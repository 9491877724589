import { render, staticRenderFns } from "./list-legal-entities.vue?vue&type=template&id=05c522e4&scoped=true"
import script from "./list-legal-entities.vue?vue&type=script&lang=js"
export * from "./list-legal-entities.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05c522e4",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TableFilters: require('/codebuild/output/src3271430876/src/web/components/table-filters.vue').default,TableActions: require('/codebuild/output/src3271430876/src/web/components/table-actions.vue').default})
